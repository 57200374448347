export const FirebaseConfig = {
	"projectId": "ut-cabs",
	"appId": "1:435474364885:web:d7a5a1f354d0eae59f255c",
	"databaseURL": "https://ut-cabs-default-rtdb.firebaseio.com",
	"storageBucket": "ut-cabs.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyB4X5v_RXLjz7wN5L2c3-SolbPlYZmHTpM",
	"authDomain": "ut-cabs.firebaseapp.com",
	"messagingSenderId": "435474364885",
	"measurementId": "G-Q9S3J6NJRM"
};